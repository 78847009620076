<template>     
  <v-main class="layout-admin">

      <div class="admin-header">       
        <div style="padding: 0 15px;display: flex;">  
          <mdicon name="menu" class="admin-mobile-menu" @click="isOpened = true" /> 
          <div style="height: 24px;"></div>
        </div>
      </div> 

      <div :style="{'width': '100%', 'display': 'flex', 'height':height_admin_content }">

        <div class="admin-sidebar">

          <div style="display: flex;flex-direction: column;align-items: center;width: 230px;">
            <img :src="require('@/assets/logo.png')" style="width: 120px; margin-bottom: 20px;"> 
            <router-link  
              class="welcome-btn"
              :to="{name:'admin-users'}" 
              v-if="account.role == 'admin'"     
            >
              <mdicon name="account-group" /> 
              Пользователи 
            </router-link> 
            <router-link  
              class="welcome-btn"
              :to="{name:'admin-sketchs'}" 
              v-if="account.role == 'admin' || account.role == 'sketch_editor'"     
            >
              <mdicon name="cards" /> 
              Эскизы 
            </router-link>             
            <router-link  
              class="welcome-btn"
              :to="{name:'admin-push'}" 
              v-if="account.role == 'admin'"     
            >
              <mdicon name="message-badge-outline" /> 
              Push 
            </router-link> 
            <router-link  
              class="welcome-btn"
              :to="{name:'account-welcome', params:{id:account.id}}"    
            >
              <mdicon name="arrow-left" /> 
              Приложение 
            </router-link> 
          </div>

        </div>

        <div class="admin-content">
          <router-view></router-view> 
        </div>
      </div>


      <VueSidePanel
        v-model="isOpened"
        lock-scroll
        side="left"
        width="600px"
      >
        <div style="padding-top: 70px; color: #f14668; display: flex;flex-direction: column;align-items: center;" >
          <div style="width: 230px;display: flex;flex-direction: column;align-items: center;">
            <img :src="require('@/assets/logo.png')" style="width: 120px; margin-bottom: 20px;"> 
            <router-link  
              class="welcome-btn"
              :to="{name:'admin-users'}"  
              @click="isOpened = false"
              v-if="account.role == 'admin'"     
            >
              <mdicon name="account-group" /> 
              Пользователи 
            </router-link> 
            <router-link  
              class="welcome-btn"
              :to="{name:'admin-sketchs'}" 
              @click="isOpened = false"
              v-if="account.role == 'admin' || account.role == 'sketch_editor'" 
            >
              <mdicon name="cards" /> 
              Эскизы 
            </router-link> 
            <router-link  
              class="welcome-btn"
              :to="{name:'admin-push'}"
              @click="isOpened = false" 
              v-if="account.role == 'admin'"     
            >
              <mdicon name="message-badge-outline" /> 
              Push 
            </router-link>

            <router-link  
              class="welcome-btn"
              :to="{name:'account-welcome', params:{id:account.id}}"    
              @click="isOpened = false"
            >
              <mdicon name="arrow-left" /> 
              Приложение 
            </router-link> 
          </div>  
        </div>

      </VueSidePanel>

  </v-main> 
</template>
<script>  
import { mapGetters, mapActions } from 'vuex'
import { isSupported, onMessage } from "firebase/messaging";
// import HeaderApp from '@/components/account/header_app.vue'

export default {
  data () {
    return {
      isOpened:false
    }
  },
  created() {    
    window.addEventListener("resize", this.resizeHandler),
    this.initNotification()   
  },
  mounted() {
    let self  = this
    setTimeout(() => {
      self.resizeHandler()
    },600);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeHandler) 
  },
  methods: {  
    ...mapActions("device", ["resizeHandler"]),
    initNotification(){
      if("Notification" in window && isSupported(this.$messaging)) {
         
          onMessage(this.$messaging, (payload) => {
            console.log("Message received. ", payload);
            new Notification(
                    payload.notification.title,
                    payload.notification
                );
          });
         
      }
    }   
  },
  computed:{   
    ...mapGetters("device", { device:"device" }), 
    ...mapGetters("account", { account:"account" }),
    height_admin_content(){ 
      let padding_bottom = Number(this.device.style.padding_bottom.replace('px', '').replace('%', ''));
      return Math.round(this.device.y - padding_bottom - 54 - 1)+"px";
    }, 
  },
  components: { 
      
  }, 
}
</script>